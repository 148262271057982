import React, {useEffect, useRef, useState} from 'react';
import classNames from "classnames";
import {numberWithCommasNull} from "../../helpers/numberWithCommas";
import {buildWSUrl} from "../../urls";
import {ConnectionStatus, getConnectionStatusMessage} from "./connectionsStatuses";
import WaitingOpponent from "./waitingOpponent";

const Lobby = (props) => {

    const socket = useRef(null);

    const {id, placeBet, tg, balance = 0, setIsGameStarted} = props

    const [waitingForOpponent, setWaitingForOpponent] = useState(false)
    const [betAmount, setBetAmount] = useState(0)
    const [message, setMessage] = useState('');
    const [connectionStatus, setConnectionStatus] = useState(ConnectionStatus.CONNECTING);
    const reconnectAttempts = useRef(0);
    const maxReconnectAttempts = 5;
    const [roomsData, setRoomsData] = useState([]);
    const [checkForGame, setCheckForGame] = useState(true);

    const amounts = [
        {
            amount: 10000,
            title: 'Rookie:'
        }, {
            amount: 100000,
            title: 'Veteran:'
        }, {
            amount: 1000000,
            title: 'Combat Master:'
        },
    ]

    const attemptReconnect = () => {
        if (reconnectAttempts.current < maxReconnectAttempts) {
            const reconnectDelay = Math.min(1000 * 2 ** reconnectAttempts.current, 3000); // Exponential backoff capped at 30 seconds
            reconnectAttempts.current += 1;

            setMessage(`Attempting to reconnect in ${reconnectDelay / 1000} seconds...`);

            setTimeout(() => {
                connectWs();
            }, reconnectDelay);
        } else {
            setMessage('Max reconnect attempts reached. Could not reconnect.');
        }
    };

    const connectWs = () => {
        socket.current = new WebSocket(buildWSUrl('/ws/broadcast'));

        socket.current.onopen = (ws) => {
            reconnectAttempts.current = 0
            setConnectionStatus(ConnectionStatus.CONNECTED);
            setMessage('');
        };

        socket.current.onerror = (error) => {
            setConnectionStatus(ConnectionStatus.NOT_RESPONDING);
            setMessage('Error connecting to the server. Please check your internet connection.');
            socket.current.close()
        };

        socket.current.onclose = (socket) => {
            if (socket.code !== 1000) {
                setConnectionStatus(ConnectionStatus.DISCONNECTED);
                setMessage('The connection to the server has been lost. We are trying to reconnect...');
                attemptReconnect();
            }
        };

        socket.current.onmessage = (event) => {
            const data = JSON.parse(event.data);
            handleSocketMessage(data)
        };
    }

    const handleSocketMessage = (data) => {
        switch (data.type) {
            case 'waitingForTheGame':
                setRoomsData(data?.data?.bets);
                break;
            case 'gameStart':
                window.localStorage.removeItem('placedBet')
                setWaitingForOpponent(false);
                setIsGameStarted(true)
                setMessage('');
                break;
            default:
                console.log('Unknown message type:', data.data.type);
        }
    }

    const sendSocketMessage = (type, data) => {
        if (socket.current && socket.current.readyState === WebSocket.OPEN) {
            socket.current.send(JSON.stringify({type, data}));
        } else {
            console.error('WebSocket is not connected');
        }
    };

    useEffect(() => {
        return () => {
            if (socket.current) {
                socket.current.close(1000, 'leave lobby');
            }
        }
    }, [])

    useEffect(() => {
        if (socket.current && socket.current.readyState === WebSocket.OPEN) {
            if (window.localStorage.getItem('placedBet')) {
                sendSocketMessage("leaveLobby", {
                    bet: Number(window.localStorage.getItem('placedBet')),
                    tgId: id
                })
                window.localStorage.removeItem('placedBet')
            }
        }
    }, [socket.current])

    useEffect(() => {
        if (!checkForGame) {
            connectWs()
        } else {
            let socket = new WebSocket(buildWSUrl(`/ws/game?client_id=${id}`));

            socket.onerror = (error) => {
                socket.close()
                setCheckForGame(false)
            };

            socket.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.type === "gameState") {
                    placeBet(data.data.bet)
                    socket.close()
                    setCheckForGame(false)
                    setIsGameStarted(true)
                }
            };
        }
    }, [checkForGame])

    if (checkForGame) {
        return <div className="info_components full_height">
            <WaitingOpponent/>
            <div className="game-content">
                <p className="connection-status">{'Loading...'}</p>
            </div>
        </div>
    }

    if (!id) {
        return <div className="info_components full_height">
            <WaitingOpponent/>
            <div className="game-content">
                <p className="connection-status">No User with such Telegram Id</p>
            </div>
        </div>
    }

    return (
        <>
            {connectionStatus !== ConnectionStatus.CONNECTED &&
                <div className="info_components full_height">
                    <WaitingOpponent/>
                    <div className="game-content">
                        <p className="connection-status">{getConnectionStatusMessage()}</p>
                        <p className="error-message">{message || 'Loading...'}</p>
                    </div>
                </div>}
            {waitingForOpponent && <div className="info_components full_height">
                <WaitingOpponent/>
                <p>{message || 'Waiting for your opponent to connect...'}</p>
            </div>}
            <div className={'lobby_game'}>
                <img className={'lobby_game_logo'} src={'/images/pvp_logo.png'} alt={'mpeppe_pvp_game'}/>
                <div className="points_amount">
                    <h2 className={'points_amount_title'}>Make your Bet:</h2>
                    <ul className={'points_amount_list'}>
                        {amounts.map(amount => {
                            return <li
                                key={amount.title}
                                onClick={() => {
                                    tg.HapticFeedback.impactOccurred('light')
                                    setBetAmount(amount.amount)
                                }}
                                className={classNames({
                                    'points_amount_item': true,
                                    'active': betAmount === amount.amount,
                                    'has_player': roomsData.find(el => el === amount.amount)
                                })}
                            ><span
                                className={'points_amount_item_title'}
                            >{amount.title}</span><span
                                className={'points_amount_item_amount'}>{numberWithCommasNull(amount.amount)}<span
                                className={'mp'}>MP</span></span></li>
                        })}
                    </ul>
                </div>
                <button
                    disabled={!betAmount || balance < betAmount}
                    className={'invite_button_button lobby_btn'}
                    onClick={() => {
                        setWaitingForOpponent(true)
                        setMessage(`Waiting for an opponent with a bet ${betAmount}...`)
                        placeBet(betAmount)
                        window.localStorage.setItem('placedBet', betAmount.toString())
                        sendSocketMessage('placeBet', {bet: betAmount, tgId: id});
                    }
                    }
                >
                    {!betAmount ? 'Make Bet' : betAmount && roomsData.find(el => el === betAmount) ? 'Fight!' : balance < betAmount ? 'Insufficient Balance' : 'Find Opponent'}
                </button>
            </div>
        </>
    );
};

export default Lobby;