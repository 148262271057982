import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as pteActions from '../store/actions/pteActions';
import {getGameDataAction, getGamesListAction, playGameAction, setGameListDefault} from "../store/actions/pteActions";
import * as dashboardActions from "../store/actions/dashboardActions";

const withPte = WrappedComponent => {
    class withPte extends Component {
        render() {
            return <WrappedComponent {...this.props} />
        }
    }

    const mapStateToProps = state => ({
        gameInitData: state.pte.game,
        isGameLoading: state.pte.isGameLoading,
        isPlayGame: state.pte.isPlayGame,
        gamesList: state.pte.gamesList,
        isGamesLoading: state.pte.isGamesLoading,
        hasError: state.pte.hasError,
        dashboard: state.dashboard.userDashboard,
        isLoadingDashboard: state.dashboard.isLoadingDashboard,
    });

    const mapDispatchToProps = (dispatch) => ({
        playGameAction: (user_id, game_data, cb) => dispatch(pteActions.playGameAction(user_id, game_data, cb)),
        getGameDataAction: (user_id, game_id) => dispatch(pteActions.getGameDataAction(user_id, game_id)),
        getGamesListAction: (user_id) => dispatch(pteActions.getGamesListAction(user_id)),
        setGameListDefault: () => dispatch(pteActions.setGameListDefault()),
        setGameDefault: () => dispatch(pteActions.setGameDefault()),
        getDashboard: (WebAppInitData, user_id) => dispatch(dashboardActions.getUserDashboardAction(WebAppInitData, user_id)),
    });

    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(withPte);
};

export default withPte;
