import React, {useState, useEffect, useRef} from 'react';
import WaitingOpponent from "./waitingOpponent";
import Lobby from "./lobby";
import classNames from "classnames";
import FightArena from "./fightArena";
import {intervalRound, intervalTurn} from "./interval";
import {makeConfetti} from "../../helpers/confetti";
import {buildWSUrl} from "../../urls";
import {ConnectionStatus, getConnectionStatusMessage} from "./connectionsStatuses";
import { useLocation } from 'react-router-dom';
import {numberWithCommasNull} from "../../helpers/numberWithCommas";

let interval;
let startGame = false;

let timeLeft = 20;

const directions = ['Head', 'Body', 'Legs'];

let playerNumber = null

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const FightingGame = (props) => {
    const query = useQuery();

    const tgId = query.get('id');

    const socket_game = useRef(null);
    const reconnectAttempts = useRef(0);
    const maxReconnectAttempts = 5;

    const {tg, balance} = props;

    const [gameState, setGameState] = useState({
        player: {attack: '', defense: '', score: 0, ready: false},
        opponent: {attack: '', defense: '', score: 0, ready: false},
        gameOver: false,
        winner: null,
        timeLeft: 20,
        showResults: false,
        roundEnded: false,
        bet: 0,
        currentRound: 1
    });

    const [disableNewGame, setDisableNewGame] = useState(true)
    const [showGameOver, setShowGameOver] = useState(false)
    const [damage, setDamage] = useState(false)
    const [roundTime, setRoundTime] = useState(10)
    const [timeRoundLeft, setTimeRoundLeft] = useState(20)
    const [roundStart, setRoundStart] = useState(false)

    const [message, setMessage] = useState('');
    const [selectedBet, setSelectedBet] = useState(null);
    const [connectionStatus, setConnectionStatus] = useState(ConnectionStatus.CONNECTING);
    const [currentAnimation, setCurrentAnimation] = useState('');
    const [isPlay1, setIsPlay1] = useState(false);
    const [isPlay2, setIsPlay2] = useState(false);
    const [roomsData, setRoomsData] = useState([]);
    const [isGameStarted, setIsGameStarted] = useState(false);

    useEffect(() => {
        return () => {
            if (socket_game.current) {
                socket_game.current.close(1000, 'User leave the Game');
            }
        };
    }, []);

    useEffect(() => {
        if(isGameStarted) {
                connectWs()
        }
    }, [isGameStarted]);

    const attemptReconnect = () => {
        if (reconnectAttempts.current < maxReconnectAttempts) {
            const reconnectDelay = Math.min(1000 * 2 ** reconnectAttempts.current, 3000); // Exponential backoff capped at 30 seconds
            reconnectAttempts.current += 1;

            setMessage(`Attempting to reconnect in ${reconnectDelay / 1000} seconds...`);

            setTimeout(() => {
                connectWs();
            }, reconnectDelay);
        } else {
            setMessage('Max reconnect attempts reached. Could not reconnect.');
        }
    };

    const connectWs = () => {
        socket_game.current = new WebSocket(buildWSUrl(`/ws/game?client_id=${query.get('id')}`));

        socket_game.current.onopen = (ws) => {
            reconnectAttempts.current = 0
            setConnectionStatus(ConnectionStatus.CONNECTED);
            setMessage('');
        };

        socket_game.current.onerror = (error) => {
            setConnectionStatus(ConnectionStatus.NOT_RESPONDING);
            setMessage('Error connecting to the server. Please check your internet connection.');
            socket_game.current.close()
        };

        socket_game.current.onclose = (socket) => {
            if (socket.code !== 1000) {
                setConnectionStatus(ConnectionStatus.DISCONNECTED);
                setMessage('The connection to the server has been lost. We are trying to reconnect...');
                attemptReconnect();
            }
        };

        socket_game.current.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.type === 'roundStart') {
                setRoundStart(true)
            }

            handleSocketMessage(data);
        };
    }

    useEffect(() => {

        if (roundStart) {
            setTimeRoundLeft(20);
            timeLeft = 20;
            interval = setInterval(() => {
                if (timeLeft > 0) {
                    timeLeft--
                    setTimeRoundLeft(timeLeft)
                } else {
                    clearInterval(interval)
                }
            }, 1000)
        } else {
            clearInterval(interval)

        }
    }, [roundStart])

    const handleSocketMessage = (data) => {
        switch (data.type) {
            case 'playerNumber':
                if(data.data.telegramId === query.get('id')) {
                    playerNumber = data.data.playerNumber
                }
                break;
            case 'waitingForOpponent':
                setMessage(`Waiting for an opponent with a bet ${data.data}...`);
                break;
            case 'gameStart':
                startGame = true
                setGameState(prevState => ({...prevState, bet: data.data.bet}));
                setMessage('');
                break;
            case 'gameState':
                setGameState(prevState => ({
                    ...prevState,
                    player: playerNumber === 0 ? data.data.player_0 : data.data.player_1,
                    opponent: playerNumber === 0 ? data.data.player_1 : data.data.player_0,
                    ...data.data,
                }));
                data.data.winner && setDisableNewGame(false)
                setScores({player_: playerNumber === 0 ? data.data.player_0.score : data.data.player_1.score, opponent_: playerNumber === 0 ? data.data.player_1.score : data.data.player_0.score})
                break;
            case 'roundResults':
                setRoundStart(false)
                setGameState(prevState => ({
                    ...prevState,
                    player: playerNumber === 0 ? data.data.player_0 : data.data.player_1,
                    opponent: playerNumber === 0 ? data.data.player_1 : data.data.player_0,
                    gameOver: data.data.gameOver,
                    winner: data.data.winner,
                    timeLeft: data.data.timeLeft,
                    bet: data.data.bet,
                    currentRound: data.data.currentRound,
                    showResults: true,
                    roundEnded: true
                }));
                break;
            case 'playerReadyUpdate':
                setGameState(prevState => ({
                    ...prevState,
                    [data.data.player === playerNumber ? 'player' : 'opponent']: {
                        ...prevState[data.data.player === playerNumber ? 'player' : 'opponent'],
                        ready: data.data.ready
                    }
                }));
                break;
            case 'roundStart':
                setGameState(prevState => ({
                    ...prevState,
                    player: {...prevState.player, ready: false, attack: '', defense: ''},
                    opponent: {...prevState.opponent, ready: false, attack: '', defense: ''},
                    currentRound: data.data.currentRound,
                    timeLeft: data.data.timeLeft,
                    showResults: false,
                    roundEnded: false
                }));
                break;
            default:
                console.log('Unknown message type:', data.data.type);
        }
    };

    const sendSocketMessage = (type, data) => {
        if (socket_game.current && socket_game.current.readyState === WebSocket.OPEN) {
            socket_game.current.send(JSON.stringify({type, data}));
        } else {
            console.error('WebSocket is not connected');
        }
    };

    const handleAction = (action, direction) => {
        setGameState(prevState => ({
            ...prevState,
            player: {
                ...prevState.player,
                [action]: direction
            }
        }));
        sendSocketMessage('action', {player: playerNumber, action, direction});
    };

    const setReady = () => {
        sendSocketMessage('playerReady', playerNumber);
    };

    const requestNewGame = () => {
        setScores({
            player_: 0,
            opponent_: 0
        })
        playerNumber = null
        setIsGameStarted(false)
        setIsPlay1(false)
        setIsPlay2(false);
        setCurrentAnimation('')
        startGame = false
        setShowGameOver(false)
        setCurrentAnimation('')
        setSelectedBet(null)
        setDamage(false)
        socket_game.current.close(1000, 'game over')
        setGameState({
            player: {attack: '', defense: '', score: 0, ready: false},
            opponent: {attack: '', defense: '', score: 0, ready: false},
            gameOver: false,
            winner: null,
            timeLeft: 20,
            showResults: false,
            roundEnded: false,
            bet: 0,
            currentRound: 1
        })
    };

    const placeBet = (bet) => {
        setSelectedBet(bet);
    };

    const {player, opponent, gameOver, winner, showResults, roundEnded, bet, currentRound} = gameState;

    const isPlayerReady = player?.attack && player?.defense;

    const [scores, setScores] = useState({
        player_: 0,
        opponent_: 0
    })

    useEffect(() => {
        if (showResults && gameOver) {
            setTimeout(() => {
                setScores({player_: player.score, opponent_: opponent.score})
            }, 4000)
        }
        if (showResults && !gameOver) {

            intervalRound(10, setRoundTime)

            setCurrentAnimation('p_1')
            setIsPlay1(true)
            setIsPlay2(false)
            setTimeout(() => {
                if (playerNumber === 1 && player.defense !== opponent.attack) {
                    setDamage(true)
                    tg?.HapticFeedback.impactOccurred('heavy')
                    setTimeout(() => {
                        setDamage(false)
                    }, 2000)
                }
                if (playerNumber === 0 && player.attack !== opponent.defense) {
                    tg?.HapticFeedback.notificationOccurred('success')
                    setTimeout(() => {
                        tg?.HapticFeedback.notificationOccurred('success')
                    }, 50)
                    setTimeout(() => {
                        tg?.HapticFeedback.notificationOccurred('success')
                    }, 70)
                }
            }, 2000)
            setTimeout(() => {
                setIsPlay1(false)
                setIsPlay2(true)
                setCurrentAnimation('p_2')
                setTimeout(() => {
                    if (playerNumber === 0 && player.defense !== opponent.attack) {
                        setDamage(true)
                        tg?.HapticFeedback.impactOccurred('heavy')
                        setTimeout(() => {
                            setDamage(false)
                        }, 2000)
                    }
                    if (playerNumber === 1 && player.attack !== opponent.defense) {
                        tg?.HapticFeedback.notificationOccurred('success')
                        setTimeout(() => {
                            tg?.HapticFeedback.notificationOccurred('success')
                        }, 50)
                        setTimeout(() => {
                            tg?.HapticFeedback.notificationOccurred('success')
                        }, 70)
                    }
                }, 2000)
            }, 5000)
            setTimeout(() => {
                setScores({player_: player.score, opponent_: opponent.score})
                setIsPlay1(false)
                setIsPlay2(false)
            }, 9000)
        }
    }, [showResults])

    useEffect(() => {
        if (gameOver) {

            if (winner === 'player_0' && playerNumber === 0) {
                setCurrentAnimation('p_2_death')
                setTimeout(() => {
                    tg?.HapticFeedback.notificationOccurred('success')
                    setTimeout(() => {
                        tg?.HapticFeedback.notificationOccurred('success')
                    }, 50)
                    setTimeout(() => {
                        tg?.HapticFeedback.notificationOccurred('success')
                    }, 70)
                }, 2000)
            }

            if (winner === 'player_1' && playerNumber === 1) {
                setCurrentAnimation('p_1_death')
                setTimeout(() => {
                    tg?.HapticFeedback.notificationOccurred('success')
                    setTimeout(() => {
                        tg?.HapticFeedback.notificationOccurred('success')
                    }, 50)
                    setTimeout(() => {
                        tg?.HapticFeedback.notificationOccurred('success')
                    }, 70)
                }, 2000)
            }

            if (winner === 'player_0' && playerNumber === 1) {
                setCurrentAnimation('p_2_death')
                    setTimeout(() => {
                        setDamage(true)
                        tg?.HapticFeedback.impactOccurred('heavy')
                    }, 2000)
            }
            if (winner === 'player_1' && playerNumber === 0) {
                setCurrentAnimation('p_1_death')
                    setTimeout(() => {
                        setDamage(true)
                        tg?.HapticFeedback.impactOccurred('heavy')
                    }, 2000)
            }

            setTimeout(() => {
                startGame = false;
                setShowGameOver(true)

                const winFeedback = () => {
                    tg?.HapticFeedback.notificationOccurred('success')
                    setTimeout(() => {
                        tg?.HapticFeedback.notificationOccurred('success')
                    }, 50)
                    makeConfetti()
                }

                const loosFeedback = () => {
                    tg?.HapticFeedback.notificationOccurred('error')
                    setTimeout(() => {
                        tg?.HapticFeedback.notificationOccurred('error')
                    }, 50)
                }

                if (winner === 'player_0' && playerNumber === 0) {
                    winFeedback()
                }
                if (winner === 'player_1' && playerNumber === 1) {
                    winFeedback()
                }
                if (winner === 'player_0' && playerNumber === 1) {
                    loosFeedback()
                }
                if (winner === 'player_1' && playerNumber === 0) {
                    loosFeedback()
                }

            }, 4000)

        }
    }, [gameOver])

    if (!isGameStarted || selectedBet === null) {
        return (
            <Lobby
                id={tgId}
                setIsGameStarted={setIsGameStarted}
                tg={tg}
                balance={balance}
                roomsData={roomsData}
                placeBet={placeBet}
            />
        );
    }

    return (
        <>
            {connectionStatus !== ConnectionStatus.CONNECTED &&
                <div className="info_components full_height">
                    <WaitingOpponent/>
                    <div className="game-content">
                        <p className="connection-status">{getConnectionStatusMessage()}</p>
                        <p className="error-message">{message}</p>
                    </div>
                </div>}
            {!playerNumber && playerNumber !== 0 &&
                <div className="info_components full_height">
                    <WaitingOpponent/>
                    <div className="game-content">
                        <p className="connection-status">{getConnectionStatusMessage()}</p>
                        <p className="error-message">{message}</p>
                    </div>
                </div>}
            <div className="fighting-game">
                <div className="game-content">
                    <div className={classNames({
                        'arena_bg': true,
                        "damage": damage
                    })}
                         style={{backgroundImage: "url(/images/pvp/arena_bg.png)"}}
                    >

                        <>
                            <div className="top_info">
                                <div className={'top_board'}>
                                    <img src={'/images/pvp/score.png'} alt={'vs'}/>
                                    <div className="score">
                                        <div className="score_round">
                                            Round {currentRound}
                                        </div>

                                        <div className="score_points">
                                    <span
                                        className={classNames({
                                            'point': true,
                                            'less': scores.player_ < scores.opponent_,
                                            'eq': scores.player_ === scores.opponent_,
                                            'more': scores.player_ > scores.opponent_
                                        })}
                                    >{scores.player_}</span>
                                            <div className={'score_players'}>
                                                <span className='player'>You</span>
                                                <span className='player'>Opponent</span>
                                            </div>

                                            <span
                                                className={classNames({
                                                    'point': true,
                                                    'less': scores.player_ > scores.opponent_,
                                                    'eq': scores.player_ === scores.opponent_,
                                                    'more': scores.player_ < scores.opponent_
                                                })}
                                            >{scores.opponent_}</span>
                                        </div>

                                        <div
                                            className={classNames({
                                                "score_timer": true,
                                                "les_10": timeLeft < 10 && timeLeft >= 5,
                                                "les_5": timeLeft < 5,
                                            })}
                                        >0:{timeLeft < 10 ? `0${timeRoundLeft}` : timeRoundLeft}</div>
                                    </div>
                                </div>

                                {message && <div className="message">{message}</div>}
                            </div>
                        </>

                        <div className="fight_arena">
                            {(() => {
                                switch (currentAnimation) {
                                    case 'p_1':
                                        return <FightArena
                                            playerNumber={playerNumber}
                                            play={isPlay1}
                                            p_1_action={'p_1_attack'}
                                            p_2_action={'p_2_def'}
                                        />
                                    case 'p_2':
                                        return <FightArena
                                            playerNumber={playerNumber}
                                            play={isPlay2}
                                            p_1_action={'p_1_def'}
                                            p_2_action={'p_2_attack'}
                                        />
                                    case 'p_1_death':
                                        return <FightArena
                                            playerNumber={playerNumber}
                                            play={true}
                                            p_1_action={'p_1_death'}
                                            p_2_action={'p_2_attack'}
                                        />
                                    case 'p_2_death':
                                        return <FightArena
                                            playerNumber={playerNumber}
                                            play={true}
                                            p_1_action={'p_1_attack'}
                                            p_2_action={'p_2_death'}
                                        />
                                    default:
                                        return <FightArena
                                            playerNumber={playerNumber}
                                            play={isPlay1 && !gameOver}
                                            p_1_action={'p_1_attack'}
                                            p_2_action={'p_2_def'}
                                        />
                                }
                            })()}
                        </div>

                    </div>

                    <div className="game-content">

                        {gameOver ? (

                            <>

                                {!showGameOver && <div className="round-results_content">
                                    {currentAnimation === 'p_2_death' && playerNumber === 0 ?
                                        <div className={'results_view'}>
                                            <div className={classNames({
                                                "results_view_point": true,
                                                "anim": player.attack !== opponent.defense
                                            })}>
                                                <span>{player.attack !== opponent.defense ? '+1' : ''}</span>
                                            </div>
                                            <h2>Your attack</h2>
                                            <p className={'results_view_attack'}>
                                                You attack to the
                                                <span
                                                    className={classNames({
                                                        "dir": true,
                                                        "up": true
                                                    })}
                                                >
                                            {player.attack}
                                        </span>
                                            </p>
                                            <p className={'results_view_defense'}>
                                                The opponent protects his
                                                <span
                                                    className={classNames({
                                                        "dir": true,
                                                    })}
                                                >
                                            {opponent.defense}
                                        </span>
                                            </p>
                                        </div> : currentAnimation === 'p_2_death' && playerNumber === 1 ?
                                            <div className={'results_view'}>
                                                <h2>Opponent attack:</h2>
                                                <p className={'results_view_attack'}>The opponent attacks the
                                                    <span
                                                        className={classNames({
                                                            "dir": true,
                                                        })}
                                                    >{opponent.attack}</span>
                                                </p>
                                                <p className={'results_view_defense'}>You protect your
                                                    <span
                                                        className={classNames({
                                                            "dir": true,
                                                            "down": true
                                                        })}
                                                    >{player.defense}</span>
                                                </p>
                                            </div> : currentAnimation === 'p_1_death' && playerNumber === 0 ?
                                                <div className={'results_view'}>
                                                    <h2>Opponent attack:</h2>
                                                    <p className={'results_view_attack'}>The opponent attacks the
                                                        <span
                                                            className={classNames({
                                                                "dir": true,
                                                            })}
                                                        >{opponent.attack}</span>
                                                    </p>
                                                    <p className={'results_view_defense'}>You protect your
                                                        <span
                                                            className={classNames({
                                                                "dir": true,
                                                                "down": true
                                                            })}
                                                        >{player.defense}</span>
                                                    </p>
                                                </div> : <div className={'results_view'}>
                                                    <div className={classNames({
                                                        "results_view_point": true,
                                                        "anim": player.attack !== opponent.defense
                                                    })}>
                                                        <span>{player.attack !== opponent.defense ? '+1' : ''}</span>
                                                    </div>
                                                    <h2>Your attack</h2>
                                                    <p className={'results_view_attack'}>
                                                        You attack to the
                                                        <span
                                                            className={classNames({
                                                                "dir": true,
                                                                "up": true,
                                                            })}
                                                        >
                                            {player.attack}
                                        </span>
                                                    </p>
                                                    <p className={'results_view_defense'}>
                                                        The opponent protects his
                                                        <span
                                                            className={classNames({
                                                                "dir": true,
                                                            })}
                                                        >
                                            {opponent.defense}
                                        </span>
                                                    </p>
                                                </div>
                                    }
                                </div>}

                                {showGameOver && <div className="round-results_content">
                                    <div className="results_view">
                                        <h2>Game Over!</h2>
                                        <p className={'results_text'}>
                                            {winner === 'player_0' && playerNumber === 0
                                                ? <span>You have Won <span style={{color: '#2EBD85'}}>{numberWithCommasNull(gameState.bet)} MP!</span></span>
                                                : winner === 'player_0' && playerNumber === 1
                                                    ?
                                                    <span>You have <span style={{color: '#e94560'}}>LOST</span>.</span>
                                                    : winner === 'player_1' && playerNumber === 0 ?
                                                        <span>You have <span
                                                            style={{color: '#e94560'}}>LOST</span>.</span> :
                                                        winner === 'player_1' && playerNumber === 1 ?
                                                            <span>You have Won <span style={{color: '#2EBD85'}}>{numberWithCommasNull(gameState.bet)} MP!</span></span> : ""
                                            }
                                        </p>
                                        <button
                                            disabled={disableNewGame}
                                            onClick={() => {
                                            requestNewGame()
                                        }} className="invite_button_button">Start new Game
                                        </button>
                                    </div>
                                </div>}

                            </>

                        ) : showResults ? (

                            <div className="round-results_content">

                                <div className="timer">
                                    <span style={{width: `${(roundTime * 100) / 10}%`}}></span>
                                </div>

                                {currentAnimation === 'p_1' ?
                                    <div className={'results_view'}>
                                        <div className={classNames({
                                            "results_view_point": true,
                                            "anim": playerNumber === 0 && player.attack !== opponent.defense
                                        })}>
                                            <span>{playerNumber === 0 && player.attack !== opponent.defense ? '+1' : ''}</span>
                                        </div>
                                        <h2>{playerNumber === 0 ? 'Your attack' : 'Opponent Attack'}</h2>
                                        <p className={'results_view_attack'}>
                                            {playerNumber === 0 ? 'You attack to the' : 'Opponent attack your'}
                                            <span
                                                className={classNames({
                                                    "dir": true,
                                                    "up": playerNumber === 0 && player.attack !== opponent.defense,
                                                    "down": playerNumber === 1 && opponent.attack !== player.defense
                                                })}
                                            >
                                            {playerNumber === 0 ? player.attack : opponent.attack}
                                        </span>
                                        </p>
                                        <p className={'results_view_defense'}>
                                            {playerNumber === 0 ? 'The opponent protects his' : 'You protect your'}
                                            <span
                                                className={classNames({
                                                    "dir": true,
                                                })}
                                            >
                                            {playerNumber === 0 ? opponent.defense : player.defense}
                                        </span>
                                        </p>
                                    </div> :
                                    <div className={'results_view'}>
                                        <div className={classNames({
                                            "results_view_point": true,
                                            "anim": playerNumber === 1 && player.attack !== opponent.defense
                                        })}>
                                            <span>{playerNumber === 1 && player.attack !== opponent.defense ? '+1' : ''}</span>
                                        </div>
                                        <h2>{playerNumber === 0 ? 'Opponent attack' : "You Attack"}</h2>
                                        <p className={'results_view_attack'}>
                                            {playerNumber === 0 ? 'The opponent attacks the' : 'You attack to the'}
                                            <span
                                                className={classNames({
                                                    "dir": true,
                                                    "up": playerNumber === 1 && player.attack !== opponent.defense,
                                                    "down": playerNumber === 0 && opponent.attack !== player.defense
                                                })}
                                            >{playerNumber === 0 ? opponent.attack : player.attack}</span>
                                        </p>
                                        <p className={'results_view_defense'}>
                                            {playerNumber === 0 ? 'You protect your' : 'The opponent protects his'}
                                            <span
                                                className={classNames({
                                                    "dir": true,
                                                })}
                                            >{playerNumber === 0 ? player.defense : opponent.defense}</span>
                                        </p>
                                    </div>
                                }
                            </div>

                        ) : (
                            <>
                                <div className="player-turn">
                                    <h2>Choose directions of Attack and Defense</h2>
                                    <div className={'player-turn_titles'}>
                                        <p className={'title_attc'}>Attack:</p>
                                        <p className={'title_def'}>Defense:</p>
                                    </div>
                                    <div className="directions">

                                        <div className="action-group">

                                            <div className="action-group_wrapper attack">
                                                <img className={'action_img'}
                                                     src={`/images/pvp/p_${playerNumber + 1}_attack.png`}
                                                     alt={'attack'}/>
                                                <div className={'action-group_buttons'}>
                                                    {directions.map((dir) => (
                                                        <button
                                                            key={`attack-${dir}`}
                                                            onClick={() => {
                                                                tg.HapticFeedback.impactOccurred('light')
                                                                handleAction('attack', dir)
                                                            }}
                                                            className={`btn ${player?.attack === dir ? 'selected' : ''}`}
                                                            disabled={player?.ready}
                                                        >
                                                            {dir}
                                                        </button>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="action-group">
                                            <div className="action-group_wrapper defense">
                                                <div className={'action-group_buttons'}>
                                                    {directions.map((dir) => (
                                                        <button
                                                            key={`defense-${dir}`}
                                                            onClick={() => {
                                                                tg.HapticFeedback.impactOccurred('light')
                                                                handleAction('defense', dir)
                                                            }}
                                                            className={`btn ${player?.defense === dir ? 'selected' : ''}`}
                                                            disabled={player?.ready}
                                                        >
                                                            {dir}
                                                        </button>
                                                    ))}
                                                </div>
                                                <img className={'action_img'}
                                                     src={`/images/pvp/p_${playerNumber + 1}_def.png`}
                                                     alt={'def'}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    onClick={setReady}
                                    disabled={!isPlayerReady || player.ready}
                                    className="invite_button_button"
                                >
                                    {player.ready ? 'Waiting for the opponent...' : 'Ready'}
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default FightingGame;