import React, {useEffect, useRef, useState} from 'react'

const Counter = ({dueDate, endTimerCallback, h_c, m_c, s_c}) => {

    let now
    let diff
    const interval = useRef()

    const timer = (finaleDate) => {

        now = new Date().getTime();
        diff = finaleDate - now;

        if (diff < 0) {
            clearInterval(interval.current)
            endTimerCallback()
            return
        }

        let days = Math.floor(diff / (1000 * 60 * 60 * 24));
        let hours = Math.floor(diff % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
        let minutes = Math.floor(diff % (1000 * 60 * 60) / (1000 * 60));
        let seconds = Math.floor(diff % (1000 * 60) / 1000);

        // eslint-disable-next-line no-unused-expressions
        days <= 9 && days > 0 ? days = `0${days}` : days === 0 ? days = `00` : days;
        // eslint-disable-next-line no-unused-expressions
        hours <= 9 ? hours = `0${hours}` : hours;
        // eslint-disable-next-line no-unused-expressions
        minutes <= 9 ? minutes = `0${minutes}` : minutes;
        // eslint-disable-next-line no-unused-expressions
        seconds <= 9 ? seconds = `0${seconds}` : seconds;

        if(h_c) {
            document.querySelector(`.${h_c}`).textContent = hours;
            document.querySelector(`.${m_c}`).textContent = minutes;
            document.querySelector(`.${s_c}`).textContent = seconds;
        } else {
            document.querySelector('.count_hours_main').textContent = hours;
            document.querySelector('.count_minutes_main').textContent = minutes;
            document.querySelector('.count_seconds_main').textContent = seconds;
        }

    }
    useEffect(() => {
        return () => {
            clearInterval(interval.current)
        }
    }, [])

    useEffect(() => {
        if (dueDate) {

            let finalDate = dueDate
            // eslint-disable-next-line react-hooks/exhaustive-deps
            now = new Date().getTime();

            let diff = finalDate - now;
            timer(finalDate);
            if (diff > 0) {
                interval.current = setInterval(() => {timer(finalDate)}, 1000);
            }
        }
    }, [dueDate])

    return (
        !dueDate ? <div className='countdown_container'><span className='button_loader'/></div> :
            <div className='countdown_container'>
            <span className={`count count_hours count_hours_main ${h_c ? h_c : ''}`}/>:
            <span className={`count count_minutes count_minutes_main ${m_c ? m_c : ''}`}/>:
            <span className={`count count_seconds count_seconds_main ${s_c ? s_c : ''}`}/>
        </div>
    )
}

export default Counter