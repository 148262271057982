import React, {useState, useEffect, useCallback} from 'react';
import './CardGame.scss';
import classNames from "classnames";

const initialBoard = Array(3).fill(null).map(() => Array(3).fill(null));
const TURN_TIME = 10;

function CardGame(props) {

    const {playGame, gameInitData, isTimeToPlay, tg} = props

    const [boards, setBoards] = useState({1: initialBoard, 2: initialBoard});
    const [currentPlayer, setCurrentPlayer] = useState(1);
    const [gameOver, setGameOver] = useState(true);
    const [scores, setScores] = useState({1: 0, 2: 0});
    const [currentCard, setCurrentCard] = useState(null);
    const [timeLeft, setTimeLeft] = useState(TURN_TIME);
    const [burningCards, setBurningCards] = useState({1: [], 2: []});

    useEffect(() => {
        if(window.localStorage.getItem('isTimeToPlay') && window.localStorage.getItem('isPlaying')) {
            playGame(0, () => resetGame(true))
            window.localStorage.removeItem('isTimeToPlay')
            window.localStorage.removeItem('isPlaying')
        } else {
            window.localStorage.removeItem('isTimeToPlay')
            window.localStorage.removeItem('isPlaying')
        }
    }, [])


    useEffect(() => {
        if(isTimeToPlay) {
            window.localStorage.setItem('isTimeToPlay', isTimeToPlay)
        } else {
            window.localStorage.removeItem('isTimeToPlay')
        }
    }, [isTimeToPlay])

    const makeRandomMove = useCallback((card) => {
        const availableCells = [];
        boards[currentPlayer].forEach((row, rowIndex) => {
            row.forEach((cell, colIndex) => {
                if (cell === null) {
                    availableCells.push([rowIndex, colIndex]);
                }
            });
        });
        if (availableCells.length > 0) {
            const [row, col] = availableCells[Math.floor(Math.random() * availableCells.length)];
            tg.HapticFeedback.notificationOccurred('success')
            playCard(row, col, card);
        }
    }, [boards, currentPlayer]);

    useEffect(() => {
        let timer;
        if (!gameOver && currentCard !== null) {
            timer = setInterval(() => {
                setTimeLeft((prevTime) => {
                    if (prevTime <= 0.05) {
                        clearInterval(timer);
                        if (currentPlayer === 1) {
                            makeRandomMove(currentCard);
                        } else {
                            playAI(currentCard);
                        }
                        return TURN_TIME;
                    }
                    return prevTime - 0.05;
                });
            }, 50);
        }

        return () => clearInterval(timer);
    }, [currentCard, gameOver, currentPlayer, makeRandomMove]);

    useEffect(() => {
        if (!gameOver && currentCard === null) {
            drawNewCard();
        }
        if (!gameOver && currentPlayer === 2 && currentCard !== null) {
            const delay = Math.floor(Math.random() * (5000 - 2000 + 1)) + 2000; // 2-5 секунд
            setTimeout(() => playAI(currentCard), delay);
        }
    }, [currentPlayer, gameOver, currentCard]);

    const drawNewCard = () => {
        const card = Math.floor(Math.random() * 6) + 1;
        setCurrentCard(card);
        setTimeLeft(TURN_TIME);
    };

    const playCard = (row, col, card) => {
        if (gameOver || card === null || boards[currentPlayer][row][col] !== null) return;

        const newBoards = {...boards};
        newBoards[currentPlayer] = newBoards[currentPlayer].map(r => [...r]);
        newBoards[currentPlayer][row][col] = card;

        const opponentPlayer = currentPlayer === 1 ? 2 : 1;

        const [updatedOpponentBoard, newBurningCards] = checkAndBurnCards(newBoards[opponentPlayer], col, card);
        newBoards[opponentPlayer] = updatedOpponentBoard;

        setBoards(newBoards);
        setBurningCards(prev => ({...prev, [opponentPlayer]: newBurningCards}));

        // Очищаем сгоревшие карты после анимации
        setTimeout(() => {
            setBurningCards(prev => ({...prev, [opponentPlayer]: []}));
        }, 500); // Длительность анимации

        const newScores = updateScores(newBoards);

        if (checkForGameEnd(newBoards[currentPlayer])) {
            const winner = newScores[1] > newScores[2] ? 1 : (newScores[2] > newScores[1] ? 2 : 0);
            // setStatus(winner === 0 ? "Ничья!" : (winner === 1 ? "Вы победили!" : "ИИ победил!"));
            playGame(winner === 0 ? 1 : winner === 1 ? 1 : 0, () => resetGame(true))
            window.localStorage.removeItem('isPlaying')

        } else {
            setCurrentPlayer(opponentPlayer);
            setCurrentCard(null);
            setTimeLeft(TURN_TIME);
        }
    };

    const playAI = (card) => {
        tg.HapticFeedback.notificationOccurred('success')
        const aiBoard = boards[2];
        const playerBoard = boards[1];
        let bestMove = null;
        let bestScore = -Infinity;

        for (let row = 0; row < 3; row++) {
            for (let col = 0; col < 3; col++) {
                if (aiBoard[row][col] === null) {
                    const newAiBoard = aiBoard.map(r => [...r]);
                    newAiBoard[row][col] = card;

                    const score = evaluateMove(newAiBoard, playerBoard, row, col, card);

                    if (score > bestScore) {
                        bestScore = score;
                        bestMove = [row, col];
                    }
                }
            }
        }

        if (bestMove) {
            playCard(...bestMove, card);
        }
    };

    const evaluateMove = (aiBoard, playerBoard, row, col, card) => {
        let score = 0;

        // 1. Оценка потенциального счета за ход
        const aiColumn = aiBoard.map(r => r[col]);
        score += calculateColumnScore(aiColumn.filter(cell => cell !== null)) * 2;  // Удваиваем вес собственного счета

        // 2. Оценка возможности сжечь карты соперника
        const playerColumn = playerBoard.map(r => r[col]);
        const burnedCards = playerColumn.filter(c => c === card).length;
        score += burnedCards * 50;  // Высокий бонус за сжигание карт соперника

        // 3. Оценка создания комбинаций с высоким множителем
        const cardCounts = aiColumn.reduce((acc, c) => {
            if (c !== null) acc[c] = (acc[c] || 0) + 1;
            return acc;
        }, {});
        const maxMultiplier = Math.max(...Object.values(cardCounts), 0);
        score += maxMultiplier * 30;  // Бонус за высокие множители

        // 4. Блокирование потенциально выгодных ходов соперника
        const playerTopCard = playerColumn[0];
        if (playerTopCard !== null && playerTopCard === card) {
            score += 40;  // Бонус за блокирование верхней карты соперника
        }

        // 5. Предпочтение центральной колонки
        if (col === 1) score += 10;

        // 6. Избегание заполнения своего поля слишком быстро
        const emptyAICells = aiBoard.flat().filter(cell => cell === null).length;
        score -= (9 - emptyAICells) * 5;  // Небольшой штраф за заполнение своего поля

        return score;
    };

    const checkAndBurnCards = (opponentBoard, col, cardValue) => {
        const burningCards = [];
        const updatedBoard = opponentBoard.map((row, rowIndex) =>
            row.map((cell, colIndex) => {
                if (colIndex === col && cell === cardValue) {
                    burningCards.push([rowIndex, colIndex]);
                    setTimeout(() => {
                        tg.HapticFeedback.impactOccurred('heavy')
                    }, 100)

                    return null;
                }
                return cell;
            })
        );
        return [updatedBoard, burningCards];
    };

    const updateScores = (boards) => {
        const newScores = {1: 0, 2: 0};

        [1, 2].forEach(player => {
            for (let col = 0; col < 3; col++) {
                const colCards = boards[player].map(row => row[col]).filter(card => card !== null);
                newScores[player] += calculateColumnScore(colCards);
            }
        });

        setScores(newScores);
        return newScores;
    };

    const calculateColumnScore = (cards) => {
        if (cards.length === 0) return 0;

        const cardCounts = cards.reduce((acc, card) => {
            acc[card] = (acc[card] || 0) + 1;
            return acc;
        }, {});

        let columnScore = 0;
        for (const [value, count] of Object.entries(cardCounts)) {
            columnScore += parseInt(value) * count * (count > 1 ? count : 1);
        }

        return columnScore;
    };

    const checkForGameEnd = (board) => {
        return board.every(row => row.every(cell => cell !== null));
    };

    const resetGame = (status) => {
        setBoards({1: initialBoard, 2: initialBoard});
        setCurrentPlayer(Math.floor(Math.random() * 2) + 1);
        setGameOver(status);
        setScores({1: 0, 2: 0});
        setCurrentCard(null);
        setTimeLeft(TURN_TIME);
    };

    const renderBoard = (player) => (
        <div className={`board ${currentPlayer !== player ? 'inactive' : ''}`}>
            {player === 1 ? [0, 1, 2].map(col => {
                const colCards = boards[player].map(row => row[col]).filter(card => card !== null);
                const colScore = calculateColumnScore(colCards);
                return (
                    <div key={`score-${player}-${col}`} className="column-score">
                        {colScore}
                    </div>
                );
            }) : null}
            {boards[player].map((row, rowIndex) =>
                row.map((cell, colIndex) => {
                    const cardCounts = boards[player].map(r => r[colIndex]).filter(c => c !== null)
                        .reduce((acc, c) => {
                            acc[c] = (acc[c] || 0) + 1;
                            return acc;
                        }, {});
                    const multiplier = cell ? cardCounts[cell] : 1;
                    const isAvailable = !gameOver && currentPlayer !== 2 && currentPlayer === player && cell === null;
                    const isBurning = burningCards[player].some(([r, c]) => r === rowIndex && c === colIndex);
                    return (
                        <div
                            key={`${player}-${rowIndex}-${colIndex}`}
                            // className={`cell ${cell ? 'filled' : 'empty'}`}
                            className={`cell ${cell ? 'filled' : 'empty'} ${isAvailable ? 'available' : ''} ${isBurning ? 'burning' : ''}`}
                            // className={`cell ${cell ? 'filled' : 'empty'} ${isAvailable ? 'available' : ''}`}
                            onClick={() => {
                                tg.HapticFeedback.notificationOccurred('success')
                                currentPlayer === 1 && player === 1 && playCard(rowIndex, colIndex, currentCard)
                            }
                            }
                        >
                            {cell && <img src={`/images/clash/${cell}.png`} alt={''}/>}
                            {multiplier > 1 && <span className="multiplier">x{multiplier}</span>}
                        </div>
                    );
                })
            )}
            {player === 1 && !gameOver && (
                <div className="column-markers">
                    {[0, 1, 2].map(col => (
                        <div
                            key={`marker-${player}-${col}`}
                            className={`column-marker ${currentPlayer === 1 && boards[player].some(row => row[col] === null) ? 'visible' : ''}`}
                        />
                    ))}
                </div>
            )}
            {player === 2 ? [0, 1, 2].map(col => {
                const colCards = boards[player].map(row => row[col]).filter(card => card !== null);
                const colScore = calculateColumnScore(colCards);
                return (
                    <div key={`score-${player}-${col}`} className="column-score">
                        {colScore}
                    </div>
                );
            }) : null}

        </div>
    );

    return (
        <>
            <div className={'top_board'}>
                <img src={'/images/vs_1.png?v=1'} alt={'vs'}/>
                <div className="score">
                    <span
                        className={classNames({
                            'points': true,
                            'less': scores[1] < scores[2],
                            'eq': scores[1] === scores[2],
                            'more': scores[1] > scores[2]
                        })}
                    >{scores[1]}</span>
                    <span
                        className={classNames({
                            'points': true,
                            'less': scores[1] > scores[2],
                            'eq': scores[1] === scores[2],
                            'more': scores[1] < scores[2]
                        })}
                    >{scores[2]}</span>
                </div>
            </div>

            <div className="card-game">

                <div className="game-container">
                    <div className="board-container ai">
                        {renderBoard(2)}
                        {!gameOver && currentPlayer === 2 && currentCard && <div className='next_card_wrapper'>
                            <p>AI Card:</p>
                            <div className={'next_card'}>
                                {currentCard && <img src={`/images/clash/${currentCard}.png`} alt={''}/>}
                            </div>
                        </div>}
                    </div>
                    {/*{!gameOver && (*/}
                    <div className="timer">
                        <span style={{width: `${(timeLeft * 100) / TURN_TIME}%`}}></span>
                    </div>
                    {/*)}*/}
                    <div className="board-container user">
                        {renderBoard(1)}
                        {!gameOver && currentPlayer === 1 && currentCard && <div className='next_card_wrapper'>
                            <p>Your Card:</p>
                            <div className={'next_card'}>

                                {currentCard && <img src={`/images/clash/${currentCard}.png`} alt={''}/>}
                            </div>
                        </div>
                        }
                    </div>
                </div>

                {gameOver && gameInitData && isTimeToPlay && (
                    <div className='play_btn_wrapper'>
                        <button
                            className={'invite_button_button'}
                            onClick={() => {
                                window.localStorage.setItem('isPlaying', 'true')
                                resetGame(false)
                            }}
                        >
                            Play Now
                        </button>
                    </div>
                )}
            </div>
        </>
    )
        ;
}

export default CardGame;