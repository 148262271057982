import * as dashboardActions from './constants.js'
import {handleRespErr} from '../../../helpers/respErrorsHandler'
import {GetAxios} from '../../../helpers/axios';
import {API_URLS, buildAPIUrl} from "../../../urls";
import {toastError, toastSuccess} from "../../../helpers/toaster";

//Get User Dashboard

export function getUserDashboardAction(WebAppInitData, user_id) {
    return dispatch => {
        let axios = GetAxios(dispatch);

        dispatch(getUserDashboardRequest());

        axios.post(buildAPIUrl(API_URLS.dashboard + `/${user_id}`), {...WebAppInitData}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                dispatch(getUserDashboardSuccess(res.data));
            })
            .catch(handleRespErr(dispatch, toastError, getUserDashboardFailure))
    }
}


function getUserDashboardRequest() {
    return {
        type: dashboardActions.GET_USER_DASHBOARD_REQUEST,
    }
}

function getUserDashboardSuccess(dashboardData) {
    return {
        type: dashboardActions.GET_USER_DASHBOARD_SUCCESS,
        payload: dashboardData
    }
}

function getUserDashboardFailure(error) {
    return {
        type: dashboardActions.GET_USER_DASHBOARD_FAILURE,
        error: error
    }
}

export function claimPointsAction(id, cb) {
    return dispatch => {
        let axios = GetAxios(dispatch);

        dispatch(claimPointsRequest());

        axios.post(buildAPIUrl(API_URLS.claim + `/${id}`), {}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                dispatch(claimPointsSuccess(res.data));
                cb(res.data.level)
            })
            .catch(handleRespErr(dispatch, toastError, claimPointsFailure))
    }
}

function claimPointsRequest() {
    return {
        type: dashboardActions.CLAIM_POINTS_REQUEST,
    }
}

function claimPointsSuccess(dashboardData) {
    return {
        type: dashboardActions.CLAIM_POINTS_SUCCESS,
        payload: dashboardData
    }
}

function claimPointsFailure(error) {
    return {
        type: dashboardActions.CLAIM_POINTS_FAILURE,
        error: error
    }
}

export function setDefaultDashboard() {
    return {
        type: dashboardActions.SET_DEFAULT_DASHBOARD
    }
}

export function getBoostsListAction(user_id) {
    return dispatch => {
        let axios = GetAxios(dispatch);

        dispatch(getBoostsListRequest());

        axios.get(buildAPIUrl(API_URLS.boosts_list + `/${user_id}`),{
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                dispatch(getBoostsListSuccess(res.data.data));
            })
            .catch(handleRespErr(dispatch, toastError, getBoostsListFailure))
    }
}


function getBoostsListRequest() {
    return {
        type: dashboardActions.GET_BOOSTS_LIST_REQUEST,
    }
}

function getBoostsListSuccess(boostData) {
    return {
        type: dashboardActions.GET_BOOSTS_LIST_SUCCESS,
        payload: boostData
    }
}

function getBoostsListFailure(error) {
    return {
        type: dashboardActions.GET_BOOSTS_LIST_FAILURE,
        error: error
    }
}

export function applyBoostAction(user_id, boost_id, type) {
    return dispatch => {
        let axios = GetAxios(dispatch);

        dispatch(applyBoostRequest());

        axios.post(buildAPIUrl(API_URLS.apply_boost + `/${user_id}` + `/${boost_id}`), {},{
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                dispatch(applyBoostSuccess(res.data.data));
                dispatch(getUserDashboardAction({}, user_id))
                switch (type) {
                    case 'full_pot':
                        return toastSuccess('Your pot is full. Claim Now!');
                    case 'pot_size':
                        return toastSuccess('Congratulations. Now you can farm more!');
                    default:
                        return toastSuccess('Successfully applied');
                }

            })
            .catch(handleRespErr(dispatch, toastError, applyBoostFailure))
    }
}

function applyBoostRequest() {
    return {
        type: dashboardActions.APPLY_BOOST_REQUEST,
    }
}

function applyBoostSuccess(response) {
    return {
        type: dashboardActions.APPLY_BOOST_SUCCESS,
        payload: response
    }
}

function applyBoostFailure(error) {
    return {
        type: dashboardActions.APPLY_BOOST_FAILURE,
        error: error
    }
}

