import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as tasksActions from '../store/actions/tasksActions';
import {
    dailyCheckAction,
    getDailyTasksAction,
    setCommunityTasksDefault,
    setDailyTasksDefault, verifyCommunityTasksAction
} from "../store/actions/tasksActions";


const withFriends = WrappedComponent => {
    class withFriends extends Component {
        render() {
            return <WrappedComponent {...this.props} />
        }
    }

    const mapStateToProps = state => ({
        communityTasks: state.tasks.community,
        isCommunityLoading: state.tasks.isCommunityLoading,
        hasError: state.tasks.hasError,
        dailyTasks: state.tasks.dailyTasks,
        isDailyLoading: state.tasks.isDailyLoading,
        isDailyStreakSuccess: state.tasks.isDailyStreakSuccess
    });

    const mapDispatchToProps = (dispatch) => ({
        getCommunityTasks: (user_id) => dispatch(tasksActions.getCommunityTasksAction(user_id)),
        verifyCommunityTasksAction: (user_id, task_id, cb) => dispatch(tasksActions.verifyCommunityTasksAction(user_id, task_id, cb)),
        getDailyTasks: (user_id) => dispatch(tasksActions.getDailyTasksAction(user_id)),
        dailyCheckAction: (user_id, day_num) => dispatch(tasksActions.dailyCheckAction(user_id, day_num)),
        setCommunityTasksDefault: () => dispatch(tasksActions.setCommunityTasksDefault()),
        setDailyTasksDefault: () => dispatch(tasksActions.setDailyTasksDefault()),
    });

    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(withFriends);
};

export default withFriends;
