export const isImagesLoaded = (imagesList, startCallBack, finishCallBack) => {
    let counter = 0;

    for (let i = 0; i <  imagesList.length; i++) {
        let image = new Image();
        image.src = imagesList[i]
        image.onload = () => {
            counter++
            if(counter === imagesList.length) {
                finishCallBack(true)
            }
        }
    }
}
