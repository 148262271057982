import React, {useEffect, useState} from 'react';
import WithLoading from "../HOC/withLoading";
import WithFrame from "../HOC/WithFrame";
import Loader from "../Commons/Loader";
import {isImagesLoaded} from "../../helpers/imagesLoaded";
import classNames from "classnames";
import {useInitData} from "@vkruglikov/react-telegram-web-app";
import {NavLink, useNavigate} from "react-router-dom";
import withAirdrop from "../../Containers/withAirdrop";
import PhoneInput from "react-phone-input-2";
import {isEmail, isValidPassword} from "./Validation";
import withTasks from "../../Containers/withTasks";
import {makeConfetti} from "../../helpers/confetti";

const Airdrop = (props) => {

    const navigate = useNavigate();
    const {
        tg,
        airdropInfo,
        isAirdropLoading,
        setAirdropDefault,
        getAirdrop,
        hasError,
        linkAccountAction,
        isProceedAirdrop,
        linkAccountData,
        verifyOtpAction,
        createAccountAction,
        getCommunityTasks,
        communityTasks,
        isCommunityLoading,
        verifyCommunityTasksAction
    } = props

    const [noAccountPopup, setNoAccountPopup] = useState(false)
    const [stage, setStage] = useState('')
    const [imagesLoaded, setImagesLoaded] = useState(false)
    const [initDataUnsafe, initData] = useInitData();

    const [email, setEmail] = useState('')
    const [otp, setOtp] = useState('')

    const [checkTerms, setCheckTerms] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isBuyComplete, setIsBuyComplete] = useState(false);
    const [buyTask, setButTask] = useState(null);

    const [signUpData, setSignUpData] = useState({
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        password: '',
        repeat_password: undefined,
        inv_currency: {label: 'Bitcoin (BTC)', value: 'btc'},
        inv_amount: ''
    })

    const createAccountHandler = () => {
        // createAccountAction(6699466438, {
        createAccountAction(initDataUnsafe?.user.id, {
            first_name: signUpData.first_name,
            last_name: signUpData.last_name,
            phone_number: signUpData.phone_number,
            email: signUpData.email,
            password: signUpData.password,
            repeat_password: signUpData.repeat_password,
            inv_currency: 'btc',
            inv_amount: 100
        })
    }

    useEffect(() => {
        tg?.BackButton?.show()
        tg?.BackButton?.onClick(() => navigate('/'))
        isImagesLoaded(['/images/friends.png'], null, setImagesLoaded)
        initDataUnsafe?.user && getAirdrop(initDataUnsafe?.user.id)
        // getAirdrop(6699466438)

        if(!communityTasks) {
            initDataUnsafe?.user && getCommunityTasks(initDataUnsafe?.user.id)
            // getCommunityTasks(6699466438)
        }

        return () => {
            setAirdropDefault()
        }
    }, []);

    useEffect(() => {
        if(communityTasks) {
            let buyTask = communityTasks.find(el => el.name === 'buy_token');

            if(buyTask) {
                setButTask(buyTask)
                if (buyTask.is_complete) {
                    setIsBuyComplete(true)
                    setTimeout(() => {
                        makeConfetti(200)
                        tg?.HapticFeedback.notificationOccurred('success')
                    }, 100)
                } else {
                    setIsBuyComplete(false)
                }
            }
        }
    }, [communityTasks])

    useEffect(() => {
        airdropInfo && airdropInfo.is_linked && setStage('linked')
        airdropInfo && !airdropInfo.is_linked && setStage('no_linked')
        linkAccountData && linkAccountData.is_client && setStage('otp')
    }, [airdropInfo, linkAccountData])

    if (!communityTasks || !imagesLoaded || (!airdropInfo && !hasError)) {
        return <Loader/>
    }

    return (
        <WithLoading
            isLoading={isAirdropLoading || isCommunityLoading}
        >
            <WithFrame
                className={'friends_page'}
                header={
                    <div>Mpeppe Airdrop</div>
                }
            >
                <img width={226} height={227} className={'friends_page_banner'}
                     src={`/images/drop.png?v=1`}
                     alt={'friends icon'}/>
                {hasError ? <div className={'has_error'}>
                        <p>Something went wrong...</p>
                        <svg className={classNames({
                            "has_error_svg": true,
                            "rotate": isAirdropLoading
                        })} onClick={() => getAirdrop(initDataUnsafe?.user.id)} version="1.1"
                             xmlns="http://www.w3.org/2000/svg"
                             x="0px" y="0px" viewBox="0 0 256 256" enableBackground="new 0 0 256 256">
                            <g>
                                <g>
                                    <g>
                                        <path fill="#fff"
                                              d="M116.3,38c-15.7,2.4-30.5,8.6-43,18.2C69.2,59.3,61.4,67,57.9,71.4c-11.8,14.8-18.8,32.8-19.7,50.9l-0.3,5.5l-14,0.1L10,128l28.2,30.7c15.5,16.9,28.4,30.8,28.6,30.8c0.3,0,12.5-13.8,27.2-30.7l26.7-30.8l-14.7-0.1l-14.8-0.1l0.2-1.1c2.2-10.2,4.6-17.3,8.6-25.3c12.3-24.2,34.6-41.7,60.9-47.7c3.8-0.9,12.8-2.2,15.4-2.2h1.9l-1.2-0.9c-1.9-1.5-12.9-6.8-17.3-8.3c-5.6-2-12.1-3.6-17.9-4.4C135.8,37.1,122.2,37.1,116.3,38z"/>
                                        <path
                                            fill="#fff"
                                            d="M162.4,96.7l-26.8,30.9l14.7,0.1l14.6,0.1l-0.2,1.5c-0.2,0.8-0.8,3.5-1.4,5.9c-7.9,31.9-33.2,57.6-64.9,66c-5.8,1.5-13.8,2.8-17.5,2.8c-1.3,0-2.4,0.2-2.4,0.5c0,1.1,11.1,6.6,18.7,9.2c43.2,14.9,90.7-4.6,111.4-45.8c5.5-11,8.6-22.4,9.3-35l0.3-4.9l13.8-0.2l13.8-0.2l-26.7-29.1c-14.6-16-27.4-29.9-28.4-30.9l-1.8-1.8L162.4,96.7z"/>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div> :
                    <>
                        {(() => {
                            switch (stage) {
                                case 'linked':
                                    return <div className='is_account'>
                                        <p className={'is_account_email'}>{airdropInfo.email}</p>
                                        <h2 className='friends_page_title'>Your account is linked.</h2>
                                        {isBuyComplete ?
                                            <p className={'is_account_descr'}>Great!!! You received a multiplier for
                                                your airdrop allocation!</p> :
                                            <>
                                                <p className={'is_account_descr'}>Get an increase in your airdrop
                                                    allocation by completing the task below.</p>
                                                <p style={{fontSize: '12px'}} className={'is_account_descr'}>Buy $MPEPPE
                                                    token in the amount of <span
                                                        style={{fontWeight: 'bold', color: '#4D9636'}}>100 USD</span> or
                                                    more to receive a multiplier for the airdrop.</p>
                                                <div style={{display: 'flex',  margin: '10px 0 20px', width: '100%'}}>
                                                <NavLink to={'https://members.mpeppe.io/buy-token'} target={'_blank'}
                                                         style={{textDecoration: 'none',   flex: 1, marginRight: '16px'}}>
                                                    <button
                                                        style={{
                                                            padding: '10px 12px',
                                                            fontSize: '14px',
                                                        }}
                                                        className="invite_button_button"
                                                    >
                                                        {'Complete the Task'}
                                                    </button>
                                                </NavLink>
                                                <button
                                                    style={{
                                                        padding: '10px 12px',
                                                        fontSize: '14px',
                                                        flex: 1,
                                                        background: 'rgba(255, 255, 255, 0.04)'
                                                    }}
                                                    className="invite_button_button no_bg"
                                                    onClick={() => {
                                                        // verifyCommunityTasksAction(6699466438, buyTask?.id, () => {
                                                            verifyCommunityTasksAction(initDataUnsafe?.user.id, buyTask.id, () => {
                                                            tg.HapticFeedback.notificationOccurred('success')
                                                            setTimeout(() => {
                                                                tg.HapticFeedback.notificationOccurred('success')
                                                            }, 50)
                                                        })
                                                    }}
                                                >
                                                    Verify
                                                </button>
                                                </div>
                                                <small className={'buy_description'}><i>All purchases made after
                                                    13.11.2024 are counted. Purchases are added up. The greater the
                                                    amount
                                                    of purchases, the greater the multiplier. The minimum total purchase
                                                    amount is $100.</i></small>
                                            </>
                                        }
                                    </div>;
                                case 'no_linked':
                                    return <>
                                        <h2 className='friends_page_title'>link your <a style={{color: '#4d9636'}}
                                                                                        href={'https://mpeppe.io/'}
                                                                                        target={'_blank'}>mpeppe.io</a> account
                                            to
                                            be eligible to claim the airdrop</h2>

                                        <div className='email_acc'>
                                            <label>Email</label>
                                            <input
                                                autoComplete={"off"}
                                                className={
                                                    classNames({
                                                        'input_email': true,
                                                        'invalid': email && !isEmail(email),
                                                        'valid': email && isEmail(email),
                                                    })
                                                }
                                                name={'email'}
                                                type={'email'}
                                                placeholder={'Enter Email'}
                                                onChange={e => setEmail(e.target.value)}
                                                value={email}
                                            />
                                            <span className={'email_acc_error'}>
                                        {email && !isEmail(email) ?
                                            <small className='d-block err-color'>Please enter a valid
                                                email</small> : null}
                                        </span>
                                        </div>
                                        <div className='invite_button'
                                             style={{margin: '0 auto 10px', maxWidth: '430px'}}>
                                            <button
                                                disabled={isProceedAirdrop || !email || !isEmail(email)}
                                                // onClick={() => linkAccountAction(6699466438, {email: email}, () => setNoAccountPopup(true), () => setEmail(''))}
                                                onClick={() => linkAccountAction(initDataUnsafe?.user.id, {email: email}, () => setNoAccountPopup(true), () => setEmail(''))}
                                                className='invite_button_button'
                                            >
                                                {isProceedAirdrop ? 'Link Account...' : 'Link Account'}
                                            </button>
                                        </div>
                                    </>;
                                case 'otp':
                                    return <>
                                        <h2 className='friends_page_title'>Verify your <a style={{color: '#4d9636'}}
                                                                                          href={'https://mpeppe.io/'}
                                                                                          target={'_blank'}>mpeppe.io</a> account.
                                            Enter OTP from your email
                                        </h2>

                                        <div className='email_acc'>
                                            <label>OTP</label>
                                            <input
                                                autoComplete={"off"}
                                                className={
                                                    classNames({
                                                        'input_email': true,
                                                        // 'invalid': email && !isEmail(email),
                                                        // 'valid': email && isEmail(email),
                                                    })
                                                }
                                                name={'otp'}
                                                type={'text'}
                                                placeholder={'Enter OTP from Email'}
                                                onChange={e => setOtp(e.target.value)}
                                                value={otp}
                                            />
                                        </div>
                                        <div className='invite_button'
                                             style={{margin: '0 auto 10px', maxWidth: '430px'}}>
                                            <button
                                                disabled={isProceedAirdrop || !otp}
                                                // onClick={() => verifyOtpAction(6699466438, {code: otp}, () => {}, () => {})}
                                                onClick={() => verifyOtpAction(initDataUnsafe?.user.id, {code: otp}, () => {}, () => {})}
                                                className='invite_button_button'
                                            >
                                                {isProceedAirdrop ? 'Verify...' : 'Verify'}
                                            </button>
                                        </div>
                                    </>;
                                case 'register':
                                    return <div className='sign_up'>
                                        <h2 className='friends_page_title'>Create your <a style={{color: '#4d9636'}}
                                                                                          href={'https://mpeppe.io/'}
                                                                                          target={'_blank'}>mpeppe.io</a> account
                                        </h2>
                                        <div className=''>
                                            {/*first name*/}
                                            <div className=" required input-group mb-3">
                                                <label>First Name<span
                                                    style={{color: '#4D9636', paddingLeft: '4px'}}>*</span></label>
                                                <input
                                                    type="text"
                                                    className={
                                                        classNames({
                                                            'form-control': true,
                                                            'is-invalid': signUpData.first_name && signUpData.first_name.length < 2
                                                        })
                                                    }
                                                    placeholder="First Name"
                                                    onChange={({currentTarget}) => setSignUpData({
                                                        ...signUpData,
                                                        first_name: currentTarget.value
                                                    })}
                                                    value={signUpData.first_name}
                                                />
                                                <span className='d-block' style={{height: '10px'}}>
                                        {signUpData.first_name && signUpData.first_name.length < 2 ?
                                            <small className='d-block err-color'>Please enter your first
                                                name</small> : null}
                                        </span>
                                            </div>

                                            {/*last name*/}
                                            <div className=" required  input-group mb-3">
                                                <label>Last Name<span
                                                    style={{color: '#4D9636', paddingLeft: '4px'}}>*</span></label>
                                                <input
                                                    type="text"
                                                    className={
                                                        classNames({
                                                            'form-control': true,
                                                            'is-invalid': signUpData.last_name && signUpData.last_name.length < 2
                                                        })
                                                    }
                                                    placeholder="Last Name"
                                                    onChange={({currentTarget}) => setSignUpData({
                                                        ...signUpData,
                                                        last_name: currentTarget.value
                                                    })}
                                                    value={signUpData.last_name}
                                                />
                                                <span className='d-block' style={{height: '10px'}}>
                                        {signUpData.last_name && signUpData.last_name.length < 2 ?
                                            <small className='d-block err-color'>Please enter your last
                                                name</small> : null}
                                        </span>
                                            </div>

                                            {/*email*/}
                                            <div className="required  input-group mb-3">
                                                <label>Email<span
                                                    style={{color: '#4D9636', paddingLeft: '4px'}}>*</span></label>
                                                <input
                                                    type="email"
                                                    className={
                                                        classNames({
                                                            'form-control': true,
                                                            'is-invalid': signUpData.email && !isEmail(signUpData.email)
                                                        })
                                                    }
                                                    placeholder="Email"
                                                    onChange={({currentTarget}) => setSignUpData({
                                                        ...signUpData,
                                                        email: currentTarget.value
                                                    })}
                                                    value={signUpData.email}
                                                />
                                                <span className='d-block' style={{height: '10px'}}>
                                        {signUpData.email && !isEmail(signUpData.email) ?
                                            <small className='d-block err-color'>Please enter a valid
                                                email</small> : null}
                                        </span>
                                            </div>

                                            {/*phone*/}
                                            <div className="text-left  input-group">
                                                <label>Phone Number<span
                                                    style={{color: '#4D9636', paddingLeft: '4px'}}>*</span></label>
                                                <PhoneInput
                                                    country={'us'}
                                                    className="marginBottom"
                                                    placeholder={'Phone Number (Optional)'}
                                                    value={signUpData.phone_number}
                                                    onChange={phone => setSignUpData({
                                                        ...signUpData,
                                                        phone_number: phone
                                                    })}
                                                />
                                            </div>
                                            <div className=" input-group mb-3 required  position-relative">
                                                <img
                                                    onTouchStart={() => setShowPassword(!showPassword)}
                                                    onTouchEnd={() => setShowPassword(!showPassword)}
                                                    onMouseDown={() => setShowPassword(!showPassword)}
                                                    onMouseUp={() => setShowPassword(!showPassword)}
                                                    className='showPassIcon' src='/images/showPass.svg'
                                                    alt='show password'
                                                />
                                                <label>Password<span
                                                    style={{color: '#4D9636', paddingLeft: '4px'}}>*</span></label>
                                                <input
                                                    type={showPassword ? 'text' : 'password'}
                                                    placeholder="Password"
                                                    onChange={({currentTarget}) => setSignUpData({
                                                        ...signUpData,
                                                        password: currentTarget.value
                                                    })}
                                                    value={signUpData.password}
                                                    className={
                                                        classNames({
                                                            'form-control': true,
                                                            'is-invalid': isValidPassword(signUpData.password)
                                                        })
                                                    }
                                                />
                                                <span className='d-block' style={{height: '10px'}}>
                                            {isValidPassword(signUpData.password) ? <small style={{height: '20px'}}
                                                                                           className='d-block err-color'>{isValidPassword(signUpData.password)}</small> : null}
                                        </span>
                                            </div>

                                            {/*confirm password*/}
                                            <div className=" input-group mb-3 required position-relative">
                                                <img
                                                    onTouchStart={() => setShowConfirmPassword(!showConfirmPassword)}
                                                    onTouchEnd={() => setShowConfirmPassword(!showConfirmPassword)}
                                                    onMouseDown={() => setShowConfirmPassword(!showConfirmPassword)}
                                                    onMouseUp={() => setShowConfirmPassword(!showConfirmPassword)}
                                                    className='showPassIcon' src='/images/showPass.svg'
                                                    alt='show password'
                                                />
                                                <label>Confirm Password<span
                                                    style={{color: '#4D9636', paddingLeft: '4px'}}>*</span></label>
                                                <input
                                                    type={showConfirmPassword ? 'text' : 'password'}
                                                    placeholder="Confirm password"
                                                    onChange={({currentTarget}) => setSignUpData({
                                                        ...signUpData,
                                                        repeat_password: currentTarget.value
                                                    })}
                                                    onFocus={() => setSignUpData({
                                                        ...signUpData,
                                                        repeat_password: ''
                                                    })}
                                                    disabled={!signUpData.password || isValidPassword(signUpData.password)}
                                                    value={signUpData.repeat_password}
                                                    className={
                                                        classNames({
                                                            'form-control': true,
                                                            'is-invalid': signUpData.password && signUpData.repeat_password && signUpData.password !== signUpData.repeat_password
                                                        })
                                                    }
                                                />
                                                <span className='d-block' style={{height: '10px'}}>
                                                        {
                                                            (signUpData.password && signUpData.repeat_password === '') || (signUpData.password && signUpData.repeat_password && signUpData.password !== signUpData.repeat_password) ?
                                                                <small className='d-block err-color'>Passwords
                                                                    mismatch</small>
                                                                : null
                                                        }
                                                    </span>
                                            </div>
                                            <div className=' input-group mb-3 required'>
                                                <div
                                                    className="custom-control custom-checkbox col-md-12 m-b-20 black-color">
                                                    <input
                                                        checked={checkTerms}
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id="checkInvestorDeclaration"
                                                        onChange={() => setCheckTerms(!checkTerms)}
                                                    />
                                                    <label style={{color: '#fff'}} className="custom-control-label"
                                                           htmlFor="checkInvestorDeclaration"><span
                                                        className='text-justify m-0 '>I agree to the <a
                                                        style={{color: '#4D9636'}}
                                                        target='_blank'
                                                        rel="noopener noreferrer"
                                                        href='https://mpeppe.io/terms-and-conditions'>Terms and Conditions</a> and <a
                                                        style={{color: '#4D9636'}}
                                                        target='_blank' rel="noopener noreferrer"
                                                        href='https://mpeppe.io/privacy-policy'>Privacy Policy</a></span><span
                                                        style={{color: '#4D9636', paddingLeft: '4px'}}>*</span></label>
                                                </div>
                                            </div>
                                            <div className='m-b-30 input-group'>
                                                <button
                                                    disabled={
                                                        !signUpData.first_name || signUpData.first_name.length < 2
                                                        || !signUpData.last_name || signUpData.last_name.length < 2
                                                        || !signUpData.email || !isEmail(signUpData.email)
                                                        || !signUpData.password
                                                        || signUpData.password !== signUpData.repeat_password
                                                        || !!isValidPassword(signUpData.password)
                                                        || !checkTerms
                                                        || isProceedAirdrop
                                                    }
                                                    className="invite_button_button"
                                                    onClick={() => createAccountHandler()}
                                                >
                                                    {isProceedAirdrop ? 'Create Account...' : 'Create Account'}
                                                </button>
                                                <span className='d-block' style={{height: '10px'}}>
                                                    {!signUpData.first_name || signUpData.first_name.length < 2
                                                    || !signUpData.last_name || signUpData.last_name.length < 2
                                                    || !signUpData.email || !isEmail(signUpData.email)
                                                    || !signUpData.password
                                                    || signUpData.password !== signUpData.repeat_password
                                                    // || !signUpData.inv_currency || !signUpData.inv_amount || !Number(signUpData.inv_amount.replace(',', ''))
                                                    || !!isValidPassword(signUpData.password)
                                                    || !checkTerms ?
                                                        <small
                                                            style={{bottom: '-12px', color: 'rgba(255, 255, 255, 0.6)'}}
                                                            className='d-block err-color'>Please fill in all
                                                            required <span style={{
                                                                color: 'rgba(24, 191, 111, 0.65)',
                                                                fontSize: '16px'
                                                            }}>*</span> fields</small> : null}
                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                default:
                                    return <></>
                            }
                        })()}
                        {noAccountPopup ? <div className={'no_account_popup_overlay'}>
                            <div className={'no_account_popup'}>
                                <h2>Sorry, there is no account with this email</h2>
                                <p>You can register it right now</p>
                                <div className={'popup_cta'}>
                                    <button onClick={() => {
                                        setNoAccountPopup(false)
                                        setStage('register')
                                    }}>Register Now
                                    </button>
                                    <button onClick={() => setNoAccountPopup(false)}>Cancel</button>
                                </div>
                            </div>
                        </div> : null}
                    </>
                }
            </WithFrame>
        </WithLoading>
    );
};

export default withAirdrop(withTasks(Airdrop));