import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as dashboardActions from '../store/actions/dashboardActions';
import {setDefaultDashboard} from "../store/actions/dashboardActions";

const withDashboard = WrappedComponent => {
    class withDashboard extends Component {
        render() {
            return <WrappedComponent {...this.props} />
        }
    }

    const mapStateToProps = state => ({
        dashboard: state.dashboard.userDashboard,
        isLoadingDashboard: state.dashboard.isLoadingDashboard,
        isClaimingPoints: state.dashboard.isClaimingPoints,
        boostsList: state.dashboard.boosts,
        isBoostsListLoading: state.dashboard.isBoostsListLoading,
    });

    const mapDispatchToProps = (dispatch) => ({
        getDashboard: (WebAppInitData, user_id) => dispatch(dashboardActions.getUserDashboardAction(WebAppInitData, user_id)),
        claimPoints: (id, cb) => dispatch(dashboardActions.claimPointsAction(id, cb)),
        setDefaultDashboard: () => dispatch(dashboardActions.setDefaultDashboard()),
        getBoostsList: (user_id) => dispatch(dashboardActions.getBoostsListAction(user_id)),
        applyBoost: (user_id, boost_id, type) => dispatch(dashboardActions.applyBoostAction(user_id, boost_id, type))
    });

    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(withDashboard);
};

export default withDashboard;
