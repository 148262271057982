import * as airdropActions from '../actions/airdropActions/constants'

const initialState = {
    airdropInfo: null,
    isAirdropLoading: true,
    isProceedAirdrop: false,
    linkAccountData: null,
    hasError: false
};

const airdropReducer = (state = initialState, action) => {
    switch (action.type) {
        case airdropActions.GET_AIRDROP_INFO_REQUEST:
            return {
                ...state,
                linkAccountData: null,
                isAirdropLoading: true
            };
        case airdropActions.GET_AIRDROP_INFO_SUCCESS:
            return {
                ...state,
                airdropInfo: action.payload,
                isAirdropLoading: false
            };
        case airdropActions.GET_AIRDROP_INFO_FAILURE:
            return {
                ...state,
                airdropInfo: null,
                isAirdropLoading: false,
                hasError: true
            };
        case airdropActions.LINK_ACCOUNT_REQUEST:
            return {
                ...state,
                isProceedAirdrop: true
            };
        case airdropActions.LINK_ACCOUNT_SUCCESS:
            return {
                ...state,
                linkAccountData: action.payload,
                isProceedAirdrop: false
            };
        case airdropActions.LINK_ACCOUNT_FAILURE:
            return {
                ...state,
                linkAccountData: null,
                isProceedAirdrop: false,
                hasError: true
            };
        case airdropActions.VERIFY_OTP_REQUEST:
            return {
                ...state,
                isProceedAirdrop: true
            };
        case airdropActions.VERIFY_OTP_SUCCESS:
            return {
                ...state,
                isProceedAirdrop: false
            };
        case airdropActions.VERIFY_OTP_FAILURE:
            return {
                ...state,
                isProceedAirdrop: false
            };
        case airdropActions.CREATE_ACCOUNT_REQUEST:
            return {
                ...state,
                isProceedAirdrop: true
            };
        case airdropActions.CREATE_ACCOUNT_SUCCESS:
            return {
                ...state,
                isProceedAirdrop: false
            };
        case airdropActions.CREATE_ACCOUNT_FAILURE:
            return {
                ...state,
                isProceedAirdrop: false
            };
        case airdropActions.SET_AIRDROP_DEFAULT:
            return {
                ...state,
                airdropInfo: null,
                isAirdropLoading: true,
                isProceedAirdrop: false,
                hasError: false
            };
        default:
            return state
    }
}

export default airdropReducer