export const GET_AIRDROP_INFO_REQUEST = 'GET_AIRDROP_INFO_REQUEST'
export const GET_AIRDROP_INFO_SUCCESS = 'GET_AIRDROP_INFO_SUCCESS'
export const GET_AIRDROP_INFO_FAILURE = 'GET_AIRDROP_INFO_FAILURE'

export const LINK_ACCOUNT_REQUEST = 'LINK_ACCOUNT_REQUEST'
export const LINK_ACCOUNT_SUCCESS = 'LINK_ACCOUNT_SUCCESS'
export const LINK_ACCOUNT_FAILURE = 'LINK_ACCOUNT_FAILURE'

export const VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST'
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS'
export const VERIFY_OTP_FAILURE = 'VERIFY_OTP_FAILURE'

export const CREATE_ACCOUNT_REQUEST = 'CREATE_ACCOUNT_REQUEST'
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS'
export const CREATE_ACCOUNT_FAILURE = 'CREATE_ACCOUNT_FAILURE'

export const SET_AIRDROP_DEFAULT = 'SET_AIRDROP_DEFAULT'