import React from "react";

export const numberWithCommas = number => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const numberWithCommasNull = number => {
    if(number || number === 0) {
        let num = Number(number).toFixed(0);
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
        return number
    }
};

export const displayBalance = (balance = 0, fixed) => {
    let rounded = Number(balance).toFixed(fixed).toString();
    let mainBal = rounded.split('.')[0]
    let decimals = rounded.split('.')[1]
    return {main: numberWithCommas(mainBal), decimals: decimals ? '.' + decimals : ''}
}

export const amountView = (balance, precision) => {
    const {main, decimals} = displayBalance(balance, precision);
    return <div className='referrals_total_amount'>
                                        <span className='main'>
                                            {main}
                                        </span>
        <span style={{fontSize: '10px'}}>
                                            {decimals}
                                        </span>
    </div>
}