import React from 'react';
import {SpriteAnimator} from "react-sprite-animator";

const WaitingOpponent = () => {
    return (
        <div>
            <SpriteAnimator
                sprite="/images/pvp/sprites/loading_walk.png"
                width={96}
                height={96}
                fps={4}
            />
        </div>
    );
};

export default WaitingOpponent;