import React, {useEffect, useState} from 'react';
import FightingGame from "./game";
import './anim.css'
import classNames from "classnames";
import WithFrame from "../HOC/WithFrame";
import HowToPlay from "../PTE/Clash/howToPlay";
import {useNavigate} from "react-router-dom";
import withDashboard from "../../Containers/withDashboard";
import {useInitData} from "@vkruglikov/react-telegram-web-app";
import {isImagesLoaded} from "../../helpers/imagesLoaded";

const slides = [
    {
        img: 'step_1',
        content: <p>Select your bet size and click 'Find Opponent'</p>
    },
    {
        img: 'step_2',
        content: <><p>Wait for an opponent with the same bet size.</p> <p>After the opponent connects, the game will
            begin.</p></>
    },
    {
        img: 'step_3',
        content: <><p>Choose directions of <span style={{color: '#e94560'}}>attack</span> and <span
            style={{color: 'rgba(255, 255, 255, 0.50)'}}>defense</span>. Click 'Ready'.</p> <p>You have 20 seconds to do
            this. The
            timer at the top is counting down.</p> <p>If you don't have time to make a choice, the system will do it
            randomly.</p></>
    },
    {
        img: 'step_4',
        content: <p>Next the battle will begin. If you choose a direction of attack different from your opponent’s
            defense, you will receive <span style={{color: '#2EBD85', fontWeight: 'bold'}}>+1</span> point.
        </p>
    },
    {
        img: 'step_5',
        content: <><p>The game continues until <span
            style={{color: '#2EBD85', fontWeight: 'bold', fontSize: '16px'}}>3</span> points. If the opponents score 3
            points at the same time, the game will continue until someone scores one more point.</p><p>The winner takes
            the opponent's bet as winnings.</p></>
    }
];

const PvpFight = (props) => {

    let images = [
        '/images/pvp/step_1.png',
        '/images/pvp/step_2.png',
        '/images/pvp/step_3.png',
        '/images/pvp/step_4.png',
        '/images/pvp/step_5.png',
    ]

    const [initDataUnsafe, initData] = useInitData();

    const {tg, dashboard, getDashboard, setDefaultDashboard,} = props

    const [howToVisible, setHowToVisible] = useState(false);
    const [imagesLoaded, setImagesLoaded] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        tg?.BackButton?.show()
        tg?.BackButton?.onClick(() => navigate('/'))
        initDataUnsafe?.user && getDashboard(initDataUnsafe, initDataUnsafe?.user?.id)

        return () => {
            setDefaultDashboard()
        }
    }, [])

    useEffect(() => {
        if (howToVisible) {
            isImagesLoaded(images, null, setImagesLoaded)
        }
    }, [howToVisible]);

    return (
        <WithFrame
            className={classNames({
                'pvp_game': true,
            })}
            header={
                <div className='penalty_shot_header'>
                    <span>Fight Arena</span>
                    <span
                        onClick={() => setHowToVisible(true)}
                    >
                        <img style={{width: '20px'}} src={'/images/clash/how.svg'} alt={'how to play'}/>
                    </span>
                </div>
            }
        >
            <FightingGame
                // balance={100000}
                balance={dashboard?.total_count}
                tg={tg}
                props={props}
            />
            {howToVisible && imagesLoaded && <HowToPlay
                img_path={'pvp'}
                slides={slides}
                setHowToVisible={setHowToVisible}
            />}
        </WithFrame>
    );
};

export default withDashboard(PvpFight);