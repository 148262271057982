import React, {useEffect, useState} from 'react';
import QrCodeWithLogo from "qrcode-with-logos";
import Loader from "../Loader";
import WithLoading from "../../HOC/withLoading";

const DetectDevice = ({t}) => {

    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        let qr = new QrCodeWithLogo({
            image: document.getElementById('qr_image'),
            content: process.env.REACT_APP_WEB_APP_URL,
            width: 380,
            logo: {
                src: '/images/flip_card/8.png'
            }
        });

        qr.getImage().then(image => {
            setLoaded(true)
        });
    }, []);

    return (
        <WithLoading
            dark
            isLoading={!loaded}
        >
        <div className='rotated_device mobile'>
            <h1>{t('no_mobile_device_title')}</h1>
            <img width={200} height={200} className={'qr_image'} src="" alt="" id="qr_image"/>
            <p>{t('no_mobile_device_subtitle')}</p>
        </div>
        </WithLoading>


    );
};

export default DetectDevice;