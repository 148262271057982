import {displayRequestErrors} from './displayRequestErrors'

export function handleRespErr(dispatch, toaster, action) {
    return (res) => {
        const {hasValidationErr, msg} = responseErrorMsg(res)
        dispatch(action(msg));
        if (hasValidationErr) {
            displayRequestErrors(msg, toaster)
        } else {
            toaster(msg)
        }
    }
}
export function handleRespErrWithCb(dispatch, toaster, action, cb) {
    return (res) => {
        const {hasValidationErr, msg} = responseErrorMsg(res)
        dispatch(action(msg));
        if (hasValidationErr) {
            displayRequestErrors(msg, toaster)
            cb()
        } else {
            toaster(msg)
            cb()
        }
    }
}

function responseErrorMsg(res) {

    if (res.response) {
        return {
            hasValidationErr: !!res?.response?.data?.error,
            msg: res?.response?.data?.error || res?.response?.data?.message || res.message || 'Something went wrong',
        }
    } else {
        return {
            hasValidationErr: false,
            msg: 'Network error'
        }
    }
}