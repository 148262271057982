import toast from 'react-hot-toast';

class Toast {
    constructor(msg, type) {
        this.msg = msg;
    }
    toastError = () => toast.error(this.msg);
    toastSuccess = () => toast.success(this.msg, {
        // style: {background: '#5cb85c', color: '#fff'},
    });
    toastInfo = () => toast(this.msg)
}

export const toastError = (msg) => {
    return new Toast(msg).toastError()
}

export const toastSuccess = (msg) => {
    return new Toast(msg).toastSuccess()
}

export const toastInfo = (msg) => {
    return new Toast(msg).toastInfo()
}