import React from 'react';
import classNames from "classnames";

const WithFrame = (props) => {

    const {className, children, header} = props

    return (
        <div className={classNames({
            'frame': true,
            [className]: true
        })}>
            <div className="frame_header">
                {header}
            </div>
            <div className='frame_content'>
                {children}
            </div>
        </div>
    );
};

export default WithFrame;