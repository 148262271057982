import * as tasksActions from './constants.js'
import {handleRespErr} from '../../../helpers/respErrorsHandler'
import {GetAxios} from '../../../helpers/axios';
import {API_URLS, buildAPIUrl} from "../../../urls";
import {toastError, toastSuccess} from "../../../helpers/toaster";

//Get User Dashboard

export function getCommunityTasksAction(user_id) {
    return dispatch => {
        let axios = GetAxios(dispatch);

        dispatch(getCommunityTasksRequest());

        axios.get(buildAPIUrl(API_URLS.tasks.community_list + `/${user_id}`), {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                dispatch(getCommunityTasksSuccess(res.data.data));
            })
            .catch(err => {
                dispatch(getCommunityTasksFailure())
            })
    }
}


function getCommunityTasksRequest() {
    return {
        type: tasksActions.GET_COMMUNITY_REQUEST,
    }
}

function getCommunityTasksSuccess(tasks) {
    return {
        type: tasksActions.GET_COMMUNITY_SUCCESS,
        payload: tasks
    }
}

function getCommunityTasksFailure(error) {
    return {
        type: tasksActions.GET_COMMUNITY_FAILURE,
        error: error
    }
}


export function verifyCommunityTasksAction(user_id, task_id, cb) {
    return dispatch => {
        let axios = GetAxios(dispatch);

        dispatch(verifyCommunityTasksRequest());

        axios.post(buildAPIUrl(API_URLS.tasks.community_claim + `/${user_id}/${task_id}`), {}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                dispatch(verifyCommunityTasksSuccess(res.data.data));
                cb && cb()
            })
            .catch(handleRespErr(dispatch, toastError, verifyCommunityTasksFailure))
    }
}


function verifyCommunityTasksRequest() {
    return {
        type: tasksActions.VERIFY_COMMUNITY_REQUEST,
    }
}

function verifyCommunityTasksSuccess(tasks) {
    return {
        type: tasksActions.VERIFY_COMMUNITY_SUCCESS,
        payload: tasks
    }
}

function verifyCommunityTasksFailure(error) {
    return {
        type: tasksActions.VERIFY_COMMUNITY_FAILURE,
        error: error
    }
}

export function setCommunityTasksDefault() {
    return {
        type: tasksActions.SET_COMMUNITY_DEFAULT
    }
}

export function getDailyTasksAction(user_id) {
    return dispatch => {
        let axios = GetAxios(dispatch);

        dispatch(getDailyTasksRequest());

        axios.get(buildAPIUrl(API_URLS.tasks.daily_list + `/${user_id}`), {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                dispatch(getDailyTasksSuccess(res.data));
            })
            .catch(err => {
                dispatch(getDailyTasksFailure())
            })
    }
}


function getDailyTasksRequest() {
    return {
        type: tasksActions.GET_DAILY_REQUEST,
    }
}

function getDailyTasksSuccess(tasks) {
    return {
        type: tasksActions.GET_DAILY_SUCCESS,
        payload: tasks
    }
}

function getDailyTasksFailure(error) {
    return {
        type: tasksActions.GET_DAILY_FAILURE,
        error: error
    }
}

export function setDailyTasksDefault() {
    return {
        type: tasksActions.SET_DAILY_DEFAULT
    }
}

export function dailyCheckAction(user_id, day_num) {
    return dispatch => {
        let axios = GetAxios(dispatch);

        dispatch(dailyCheckActionRequest());

        axios.post(buildAPIUrl(API_URLS.tasks.daily_check + `/${user_id}`), {day_num: day_num}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                dispatch(dailyCheckActionSuccess(res.data));
            })
            .catch(err => {
                dispatch(dailyCheckActionFailure())
            })
    }
}

function dailyCheckActionRequest() {
    return {
        type: tasksActions.CHECK_DAILY_REQUEST,
    }
}

function dailyCheckActionSuccess(tasks) {
    return {
        type: tasksActions.CHECK_DAILY_SUCCESS,
        payload: tasks
    }
}

function dailyCheckActionFailure(error) {
    return {
        type: tasksActions.CHECK_DAILY_FAILURE,
        error: error
    }
}
