import {combineReducers} from 'redux'
import {persistReducer} from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'

//reducers
import dashboard from './dashboardReducer'
import pte from './pteReducer'
import airdrop from './airdropReducer'
import friends from './friendsReducer'
import tasks from './taskReducer'

const rootPersistConfig = {
    key: 'root',
    storage: storageSession,
    whitelist: ['options', 'user'],
};

const rootReducer = combineReducers({
    dashboard,
    pte,
    friends,
    tasks,
    airdrop
});

export default persistReducer(rootPersistConfig, rootReducer)
