export const ConnectionStatus = {
    CONNECTING: 'CONNECTING',
    CONNECTED: 'CONNECTED',
    NOT_RESPONDING: 'NOT_RESPONDING',
    DISCONNECTED: 'DISCONNECTED'
};

export const getConnectionStatusMessage = (connectionStatus) => {
    switch (connectionStatus) {
        case ConnectionStatus.CONNECTING:
            return 'Connecting to the server...';
        case ConnectionStatus.CONNECTED:
            return 'Connected to server';
        case ConnectionStatus.NOT_RESPONDING:
            return 'Server is not responding';
        case ConnectionStatus.DISCONNECTED:
            return 'The connection to the server is lost';
        default:
            return '';
    }
};