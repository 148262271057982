import confetti from 'canvas-confetti'

//
// import JSConfetti from 'js-confetti'
//
// const jsConfetti = new JSConfetti()
//
// export const makeConfetti = () => {
//     jsConfetti.addConfetti({
//         confettiColors: [
//             '#FFCE83', '#FE8C81', '#3602A3'
//         ],
//         confettiRadius: 4,
//         confettiNumber: 150,
//     })
// }



export const makeConfetti = (count) => {
    confetti({
        zIndex: 1060,
        particleCount: count || 100,
        spread: 50,
        origin: { y: 0.6 }
    });
}