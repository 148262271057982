import * as pteActions from './constants.js'
import {handleRespErr} from '../../../helpers/respErrorsHandler'
import {GetAxios} from '../../../helpers/axios';
import {API_URLS, buildAPIUrl} from "../../../urls";
import {toastError} from "../../../helpers/toaster";

//Get User Dashboard

export function getGameDataAction(user_id, game_id) {
    return dispatch => {
        let axios = GetAxios(dispatch);

        dispatch(getGameDataActionRequest());

        axios.get(buildAPIUrl(API_URLS.pte.game_data + `/${user_id}/${game_id}`), {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                dispatch(getGameDataActionSuccess(res.data));
            })
            .catch(handleRespErr(dispatch, toastError, getGameDataActionFailure))
    }
}


function getGameDataActionRequest() {
    return {
        type: pteActions.GET_GAME_DATA_REQUEST,
    }
}

function getGameDataActionSuccess(game_data) {
    return {
        type: pteActions.GET_GAME_DATA_SUCCESS,
        payload: game_data
    }
}

function getGameDataActionFailure(error) {
    return {
        type: pteActions.GET_GAME_DATA_FAILURE,
        error: error
    }
}

export function playGameAction(user_id, game_data, cb) {
    return dispatch => {
        let axios = GetAxios(dispatch);

        dispatch(playGameActionRequest());

        axios.post(buildAPIUrl(API_URLS.pte.play + `/${user_id}`), {...game_data}, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                dispatch(playGameActionSuccess(res.data));
                cb(res.data)
            })
            .catch(handleRespErr(dispatch, toastError, playGameActionFailure))
    }
}


function playGameActionRequest() {
    return {
        type: pteActions.PLAY_GAME_REQUEST,
    }
}

function playGameActionSuccess(game_result) {
    return {
        type: pteActions.PLAY_GAME_SUCCESS,
        payload: game_result
    }
}

function playGameActionFailure(error) {
    return {
        type: pteActions.PLAY_GAME_FAILURE,
        error: error
    }
}


export function getGamesListAction(user_id) {
    return dispatch => {
        let axios = GetAxios(dispatch);

        dispatch(getGamesListActionRequest());

        axios.get(buildAPIUrl(API_URLS.pte.list + `/${user_id}`), {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                dispatch(getGamesListActionSuccess(res.data.data));
            })
            .catch(handleRespErr(dispatch, toastError, getGamesListActionFailure))
    }
}


function getGamesListActionRequest() {
    return {
        type: pteActions.GET_GAMES_LIST_REQUEST,
    }
}

function getGamesListActionSuccess(gamesList) {
    return {
        type: pteActions.GET_GAMES_LIST_SUCCESS,
        payload: gamesList
    }
}

function getGamesListActionFailure(error) {
    return {
        type: pteActions.GET_GAMES_LIST_FAILURE,
        error: error
    }
}

export function setGameListDefault() {
    return {
        type: pteActions.SET_GAMES_LIST_DEFAULT
    }
}

export function setGameDefault() {
    return {
        type: pteActions.SET_GAME_DEFAULT
    }
}