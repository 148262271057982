export function isLetters (value) {
    let letters = /[A-z]/g;
    return value.match(letters)
}

export function isNumbers (value) {
    let numbers = /[0-9]/g;
    return value.match(numbers)
}

export function isEmail(value) {
    return /^([a-zA-Z0-9_.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value);
}

export function isValidPassword (password) {
    if (password) {
        if (password.length > 5) {
            if (!isLetters(password)) {
                return 'Password must contain at least one letter';
            } else if (!isNumbers(password)) {
                return 'Password must contain at least one digit';
            } else {
                return '';
            }
        } else {
            return 'Please enter at least 6 characters';
        }
    }
}

export function isFullYear(value) {
    let birthDate = new Date(value).getTime();
    let dateOnFullYear = birthDate + 31536000000*18;

    if(dateOnFullYear > Date.now()){
        return 'You must be 18 or over.'
    } else {
        return ''
    }
}

export function isUkPostcode(str) {
    return /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$/.test(str);
}

export function isLeapYear (year) {
    return year % 4 === 0;
}
